<script setup lang="ts"></script>

<template>
  <div class="footage">
    <p>© 2024 Meowtopia 版权归属 请勿侵权</p>

    <a href="https://beian.miit.gov.cn/" target="_blank"
      >浙ICP备2024103738号-3</a
    >
  </div>
</template>

<style lang="scss" scoped>
.footage {
  @apply py-6;
  @apply flex justify-around;
  @apply text-sm; // text
}
</style>
